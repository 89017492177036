import { Component, Inject, Input } from "@angular/core";
import { CR_CONFIG } from "../../../../shared";
import { LocalizedText } from "../../../../core";
import text from '../../resources/locale/en.json'
import { StateService } from "@uirouter/core";
import { Hours, PhonePreviewList } from "../../constants/hours.types";
import { ParkHourStatus } from "../../constants/constants.utils";
import { DatePipe } from "@angular/common";
import { LocaleService } from "../../../../shared/services/locale/locale.service";

@Component({
    selector: 'cr-venue-hours-phone-preview',
    templateUrl: './venue-hours-phone-preview.component.html',
    styleUrls: ['./venue-hours-phone-preview.component.scss']
})
export class VenueHoursPhonePreviewComponent {
    @Input()
    phonePreviewList: PhonePreviewList
    @Input()
    timezone: string

    text: LocalizedText;
    venueName: string;

    constructor(
        @Inject(CR_CONFIG) public crConfig,
        private datePipe: DatePipe,
        public state: StateService,
        private crLocaleService: LocaleService
    ) {
        this.text = text as LocalizedText
        const venue = crConfig.customer.venues.find((venue) => {
            return venue.id === this.state.params.venueId;
        })
        this.venueName = venue.venueName;
    }

    getFormattedTimeString(startDate: Date, endDate: Date) {
        const timezonedStartDate = this.convertToTimezonedDate(startDate);
        const timezonedEndDate = this.convertToTimezonedDate(endDate);

        const formattedStartTime = this.datePipe.transform(timezonedStartDate, 'shortTime');
        const formattedEndTime = this.datePipe.transform(timezonedEndDate, 'shortTime');

        return `${formattedStartTime} - ${formattedEndTime}`;
    }

    getFormattedLabel(hour: Hours) {
        return `${hour.label}:`
    }

    getVenueById(venue) {
        return venue.id === this.state.params.venueId;
    }

    isVenueOpen(hour: Hours) {
        return hour.status === ParkHourStatus.OPEN
    }

    convertToTimezonedDate(date: Date): string {
        return date.toLocaleString(
            this.crLocaleService.getLocale(this.state.params.venueId).current,
            { timeZone: this.timezone }
        );
    }
}
