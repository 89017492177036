import { Component, Inject, OnInit } from "@angular/core";
import text from './resources/locale/en.json';
import { CR_CONFIG, CR_CONSTANTS, ErrorLoggingService, VenueService } from "../../shared";
import { Config, Constants, LocalizedText } from "../../core";
import { EntityService } from "../../shared/services/entity/entity.service";
import { ToastService } from "../../ng1-factories";
import { StateService } from "@uirouter/core";
import { HoursList, PhonePreviewList, VenueHoursList } from "./constants/hours.types";

@Component({
    selector: 'cr-venue-hours',
    templateUrl: './venue-hours.component.html',
    styleUrls: ['./venue-hours.component.scss']
})
export class VenueHoursComponent implements OnInit {

    text: LocalizedText;
    isLoading = false;
    isSaving = false;
    isCreateEnabled = true;
    accessError: any;
    dataError = false;
    params: any;
    hoursList: HoursList;
    futureHoursList: HoursList;
    phonePreviewHours: PhonePreviewList = []
    venueHoursList: VenueHoursList = []
    timezone: string;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        private crEntityService: EntityService,
        private crToastService: ToastService,
        venueService: VenueService,
        public state: StateService,
        private crErrorLoggingService: ErrorLoggingService,
    ) {
        this.text = text as LocalizedText
        this.params = {}
        this.timezone = venueService.getTimezone(state.params.venueId).name
    }

    ngOnInit(): void {
        if(this.state.params.toast) {
            this.crToastService.toast(
                this.state.params.toast.msg,
                this.state.params.toast.timeToLive
            );
        }

        this.isLoading = true;
        this.dataError = false;

        this.crEntityService
            .getVenueHoursByDay(
                this.constants.entity.types.VENUE_HOURS,
                {
                    venueId: this.state.params.venueId,
                    days: '7'
                },
                '',
                this.state.params.customerId
            ).then((res) => {
                this.hoursList = res;
                if(this.hoursList.days.length === 0) {
                    return;
                }
                this.hoursList.days.shift();
                this.generatePhonePreviewList();
                if(this.phonePreviewHours.length >= 5) {
                    this.isCreateEnabled = false;
                }
                this.crEntityService
                    .getVenueHoursByDay(
                        this.constants.entity.types.VENUE_HOURS,
                        {
                            venueId: this.state.params.venueId,
                            days: '15'
                        },
                        '',
                        this.state.params.customerId
                    ).then((res) => {
                        this.futureHoursList = res;
                        this.futureHoursList.days.shift();
                        this.generateVenueHoursList();
                    }).catch((err) => {
                        this.dataError = true;
                        this.crErrorLoggingService.logError('Could not retrieve venue hours', err)
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }).catch((err) => {
                this.dataError = true;
                this.crErrorLoggingService.logError('Could not retrieve venue hours', err)
            }).finally(() => {
                this.isLoading = false;
            });
    }

    isAdd(): void {
        this.state.go('client.venue-hours.create', this.getStateParams())
    }

    isSettings(): void {
        this.state.go('client.venue-hours.edit-order', this.getStateParams())
    }

    getStateParams() {
        return {
            customerId: this.state.params.customerId,
            venueId: this.state.params.venueId
        }
    }

    generatePhonePreviewList() {
        const todaysHours = this.hoursList.days[0].hours;
        todaysHours.forEach((hour, idx) => {
            const startDate = new Date(hour.schedule.start)
            const endDate = new Date(hour.schedule.end)

            this.phonePreviewHours.push({
                label: hour.label,
                startDate: startDate,
                endDate: endDate,
                status: hour.statusLabel
            })

            this.venueHoursList.push({
                label: hour.label,
                venueHours: []
            })
        });
    }

    generateVenueHoursList() {
        const hoursList = this.hoursList.days
        hoursList.forEach( ( dateHours, hoursIdx ) => {
            const rawDate = dateHours.date.replace(/-/g, '/')
            const date = new Date(rawDate);
            dateHours.hours.forEach((hour, index) => {
                const futureStatus = this.futureHoursList.days[hoursIdx + 7].hours[index].status
                this.venueHoursList[index].venueHours.push({
                    statusLabel: hour.statusLabel,
                    day: `${date.toLocaleDateString('en-US', { weekday: "long" })},`,
                    date: date,
                    startDate: new Date(hour.schedule.start),
                    endDate: new Date(hour.schedule.end),
                    isTempClosure: this.isTemporaryClosed(hour.status, futureStatus)
                })
            })
        })
    }

    isTemporaryClosed(firstWeek: string, secondWeek: string): boolean {
        if(firstWeek === 'CLOSED' && secondWeek !== 'CLOSED') {
            return true;
        } else {
            return false;
        }
    }
}
