import { Component, Input, OnInit } from "@angular/core";
import { VenueHoursList } from "../../constants/hours.types";

@Component({
    selector: 'cr-venue-hours-drop-down-list',
    templateUrl: './venue-hours-drop-down-list.component.html',
    styleUrls: ['./venue-hours-drop-down-list.component.scss']
})
export class VenueHoursDropDownListComponent{
    @Input()
    venueHoursList: VenueHoursList;
    @Input()
    timezone: string;
}
