import { Component, Inject, Input } from "@angular/core";
import { LocalizedText } from "../../../../../core";
import text from '../../../resources/locale/en.json';
import { ParkHourStatus, WeekDays } from "../../../constants/constants.utils";
import { VenueHours } from "../../../constants/hours.types";
import { DatePipe } from "@angular/common";
import { StateService } from "@uirouter/core";
import { LocaleService } from "../../../../../shared/services/locale/locale.service";
import { CR_CONFIG } from "../../../../../shared";

@Component({
    selector: 'cr-venue-hours-drop-down',
    templateUrl: './venue-hours-drop-down.component.html',
    styleUrls: ['./venue-hours-drop-down.component.scss']
})
export class VenueHoursDropDownComponent {
    @Input()
    venueHours: {
        label: string;
        venueHours: VenueHours[];
    };
    @Input()
    timezone: string;

    label: string;
    isOpen = false;
    text: LocalizedText;
    days = [];

    constructor(
        private datePipe: DatePipe,
        private state: StateService,
        private crLocaleService: LocaleService,
        @Inject(CR_CONFIG) private crConfig
    ) {
        this.text = text as LocalizedText
        this.label = ""
        this.days = WeekDays
        this.timezone = crConfig.customer.venues.find(((venue) => {
            return venue.id === this.state.params.venueId;
        })).timezone;
    }

    getDateByDay(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date
        })
        return hours.date
    }

    getHoursByDay(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date
        })

        const timezonedStartDate =this.convertToTimezonedDate(hours.startDate);
        const timezonedEndDate = this.convertToTimezonedDate(hours.endDate);

        const formattedStartTime = this.datePipe.transform(timezonedStartDate, 'shortTime');
        const formattedEndTime = this.datePipe.transform(timezonedEndDate, 'shortTime');

        return hours.statusLabel === ParkHourStatus.OPEN
            ? `${formattedStartTime} - ${formattedEndTime}`
            : "CLOSED"
    }

    convertToTimezonedDate(date: Date): string {
        return date.toLocaleString(
            this.crLocaleService.getLocale(this.state.params.venueId).current,
            { timeZone: this.timezone }
        );
    }

    isEditHours() {
        this.state.go('client.venue-hours.edit-hours', { venueLabel: this.venueHours.label })
    }


    getStateParams() {
        return {
            customerId: this.state.params.customerId,
            venueId: this.state.params.venueId,
            venueHours: this.venueHours
        }
    }

    isTemporaryClosure(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date;
        });
        return hours.isTempClosure;
    }

    isToday(date: Date): boolean {
        const currentDate = new Date();
        return date.setHours(0,0,0,0) == currentDate.setHours(0,0,0,0);
    }
}
